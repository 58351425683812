import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPagination from "../../components/customPagination";
import { faSearch, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import AlertPopup from "../../components/alertPopup";
import PopupModal from "../../components/popupModal";
import LoadingScreen from "../../components/Loading/LoadingScreen";
import AdminProfilePopup from "../../components/AdminProfilePopup";
import excel from "../../assets/images/excel.png";
import * as XLSX from "xlsx";
import { Pagination } from "@mui/material";
const ApprovedRequestsPage = () => {
  const [allRequestsData, setAllRequestsData] = useState([]);
  const [FilterallData, setFilterAllData] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hideTable, setHideTable] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [note, setNote] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [openRequestPopup, setOpenRequestPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const OpenVeiwUser = (e) => {
    console.log(e.id);
    // setSelectedId(e.id);
    handleOpen();
  };
  useMemo(() => {
    const getRequests = async () => {
      let url = `${axios.defaults.baseURL}/approvedRequests`;
      await axios
        .get(url, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setAllData(response.data.data);
            setAllRequestsData(response.data.data);
            setFilterAllData(response.data.data);
            console.log(response.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            // // console.log(error.response);
            // // console.log("server responded");
          } else if (error.request) {
            // // console.log("network error");
          } else {
            // // console.log(error);
          }
        });
    };
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!openPopup]);
  const handlePageChange = (e) => {
    setPage(e);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const handleRequestPopup = (id, status) => {
    setId(id);
    setRequestStatus(status);
    setOpenRequestPopup((value) => !value);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostsIndex = currentPage * postsPerPage; // 1 * 20 = 20
  const firstPostIndex = lastPostsIndex - postsPerPage; // 20 - 20 = 0
  const currentPosts = FilterallData.slice(firstPostIndex, lastPostsIndex);
  const handelPages = (e, p) => {
    setCurrentPage(p);
  };

  const filterSearchRequestes = (e) => {
    if (e.target.value === "") {
      // setFilterdAllData(allAdminsData);
      setFilterAllData(allData);
    } else {
      setFilterAllData(
        allData.filter((f) => {
          return f.user_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        })
      );
    }
  };
  const handleStatus = () => {
    // // console.log(id, requestStatus, note);
    axios
      .post(
        `${axios.defaults.baseURL}/approvedRequests/${id}`,
        {
          status: requestStatus,
          note: note,
        },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 202) {
          showAlertPopup(response.data.status, response.data.message);
          // // console.log(response);
          setNote("");
          setRequestStatus(null);
          setId(null);
        }
      })
      .catch((error) => {
        if (error.response) {
          showAlertPopup(
            error.response.data.message,
            error.response.data.data.id || error.response.data.data.status
          );
          setNote("");
          setRequestStatus(null);
          setId(null);
          // // console.log(error.response);
          // // console.log("server responded");
        } else if (error.request) {
          showAlertPopup(
            error.response.data.message,
            error.response.data.data.id || error.response.data.data.status
          );
          setNote("");
          setRequestStatus(null);
          setId(null);
          // // console.log("network error");
        } else {
          showAlertPopup(
            error.response.data.message,
            error.response.data.data.id || error.response.data.data.status
          );
          setNote("");
          setRequestStatus(null);
          setId(null);
          // // console.log(error);
        }
      });
  };

  const handleExport = () => {
    // تحديد الجدول الذي تريد تصديره
    const table = document.getElementById("excelProcess");

    // تحويل الجدول إلى JSON
    const ws = XLSX.utils.table_to_sheet(table);

    // إنشاء ملف Excel
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // تنزيل الملف
    XLSX.writeFile(wb, "Approved_Requests.xlsx");
  };

  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      <div className="mainContainer">
        <Header />
        <div>
          <TitleHeader
            title="Manage Requests"
            subTitle="Requests"
            showAddButton={false}
          />
          {!hideTable ? (
            <>
              <div
                className="filterHeader"
                style={{ justifyContent: "flex-start" }}
              >
                {!loading ? (
                  <div
                    style={{
                      padding: "0 30px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <p
                      style={{
                        color: "var(--c600)",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    >
                      Requests ({allData?.length})
                    </p>
                    <div style={{ position: "relative", marginRight: "16px" }}>
                      <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                      <input
                        type="text"
                        value={searchInput}
                        onChange={(e) => {
                          filterSearchRequestes(e);
                          setSearchInput(e.target.value);
                        }}
                        className="searchInput"
                        placeholder="Search Requests"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="ExcelSheet">
                <button onClick={handleExport} className="pdddddfx2">
                  Excel Sheet
                </button>
              </div>
              <div className="mainTable">
                {loading === true ? (
                  <LoadingScreen />
                ) : (
                  <>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr className="tableHeaderContainer">
                          <th>User Name</th>
                          <th></th>
                          <th>Course</th>
                          <th>Provider</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                        {currentPosts.map((e) => {
                          return (
                            <tr key={e.id}>
                              <td
                                className="userNamexx03"
                                onClick={() => {
                                  OpenVeiwUser(e);
                                  setSelectedId(e.user_id);
                                }}
                              >
                                {e.user_name}
                              </td>
                              <td>{e.organization}</td>
                              <td>{e.course}</td>
                              <td>{e.provider}</td>
                              <td>{e.price} EGP</td>
                              <td>
                                <div className="requestsActionsContainer">
                                  {e.status === "Booked" ? (
                                    <div className="approvedText">
                                      <FontAwesomeIcon icon={faCheck} />
                                      Booked
                                    </div>
                                  ) : e.status === "Canceled" ? (
                                    <div className="rejectedText">
                                      <FontAwesomeIcon icon={faXmark} />
                                      Canceled
                                    </div>
                                  ) : e.status === "Approved" || "New" ? (
                                    <div
                                      style={{ width: "130px", margin: "auto" }}
                                    >
                                      <button
                                        className="bookedButton"
                                        onClick={() => {
                                          handleRequestPopup(e.id, 4);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </button>
                                      <button
                                        className="canceledButton"
                                        onClick={() => {
                                          handleRequestPopup(e.id, 3);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faXmark} />
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <table
                      style={{ display: "none", visibility: "hidden" }}
                      id="excelProcess"
                    >
                      <tbody>
                        <tr className="tableHeaderContainer">
                          <th>User Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Course</th>
                          <th>Provider</th>
                          <th>Price</th>
                          <th>State</th>
                        </tr>
                        {allRequestsData.map((e) => {
                          return (
                            <tr key={e.id}>
                              <td
                                onClick={() => {
                                  OpenVeiwUser(e);
                                  setSelectedId(e.user_id);
                                }}
                              >
                                {e.user_name}
                              </td>
                              <td>{e.email}</td>
                              <td>{e.phone}</td>
                              <td>{e.course}</td>
                              <td>{e.provider}</td>
                              <td>{e.price} EGP</td>
                              <td>{e.status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Pagination
                        count={Math.ceil(FilterallData.length / 8)}
                        color="primary"
                        size={"small"}
                        onChange={handelPages}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}
          {openPopup ? (
            <AlertPopup
              title={status}
              subTitle={message}
              openPopup={handlePopup}
            />
          ) : null}
          {openRequestPopup ? (
            <PopupModal
              openPopup={handleRequestPopup}
              title="type a note"
              handleStatus={handleStatus}
              note={note}
              setNote={setNote}
            />
          ) : null}
        </div>
      </div>
      <AdminProfilePopup
        open={open}
        handleClose={handleClose}
        id={selectedId}
      />
    </div>
  );
};

export default ApprovedRequestsPage;
