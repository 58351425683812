import axios from "../../axios";
import { useMemo, useState } from "react";
import SideBar from "../../components/sideBar/sideBar";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPagination from "../../components/customPagination";
import { faSearch, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import AlertPopup from "../../components/alertPopup";
import LoadingScreen from "../../components/Loading/LoadingScreen";

const RequestsPage = () => {
  const [allRequestsData, setAllRequestsData] = useState([]);
  const [filterallRequestsData, setfilterAllRequestsData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hideTable, setHideTable] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  console.log("fsdfsd");
  useMemo(() => {
    const getRequests = async () => {
      let url = `${axios.defaults.baseURL}/organization`;
      await axios
        .get(url, {
          headers: {
            authorization: axios.defaults.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setAllData(response.data.data);
            setfilterAllRequestsData(response.data.data);
            setAllRequestsData(response.data.data.paginatedData);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message === "Invalid ability provided.") {
              setLoading(false);
              setHideTable(true);
            }
            // // console.log(error.response);
            // // console.log("server responded");
          } else if (error.request) {
            // // console.log("network error");
          } else {
            // // console.log(error);
          }
        });
    };
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, page, openPopup]);
  const handlePageChange = (e) => {
    setPage(e);
  };
  const handlePopup = (value) => {
    setOpenPopup(value);
  };
  const showAlertPopup = (status, message) => {
    handlePopup(true);
    setMessage(message);
    setStatus(status);
  };
  const handleStatus = (id, status) => {
    axios
      .post(
        `${axios.defaults.baseURL}/organization/requests/${id}`,
        {
          status: status,
        },
        {
          headers: {
            authorization: axios.defaults.token,
          },
        }
      )
      .then((response) => {
        if (response.status === 202) {
          showAlertPopup(response.data.status, response.data.message);
          // // console.log(response);
        }
      })
      .catch((error) => {
        if (error.response) {
          // // console.log(error.response);
          // // console.log("server responded");
        } else if (error.request) {
          // // console.log("network error");
        } else {
          // // console.log(error);
        }
      });
  };

  const filterAdminsInput = (e) => {
    if (e === "") {
      // setFilterdAllData(allAdminsData);
      setfilterAllRequestsData(allData);
    } else {
      setfilterAllRequestsData(
        allData.filter((f) => {
          return (
            f.name.toLowerCase().includes(e.toLowerCase()) ||
            f.name.toLowerCase().includes(e.toLowerCase())
          );
        })
      );
    }
  };
  return (
    <div style={{ display: "flex" }}>
      <SideBar />
      {/* mainContainer*/}
      <div className="mainContainer">
        <Header />
        <div>
          <TitleHeader
            title="Manage Requests"
            subTitle="Requests"
            showAddButton={false}
          />
          {!hideTable ? (
            <>
              <div
                className="filterHeader"
                style={{ justifyContent: "flex-start" }}
              >
                {!loading ? (
                  <div
                    style={{
                      padding: "0 30px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <p
                      style={{
                        color: "var(--c600)",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    >
                      Requests ({allData.total})
                    </p>
                    <div style={{ position: "relative", marginRight: "16px" }}>
                      <FontAwesomeIcon icon={faSearch} className="searchIcon" />
                      <input
                        type="text"
                        value={searchInput}
                        onChange={(e) => {
                          filterAdminsInput(e.target.value);
                          setSearchInput(e.target.value);
                        }}
                        className="searchInput"
                        placeholder="Search Requests"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="mainTable">
                {loading === true ? (
                  <LoadingScreen />
                ) : (
                  <>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr className="tableHeaderContainer">
                          <th>User Name</th>
                          <th>E-Mail</th>
                          <th>Course</th>
                          <th>Provider</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                        {allRequestsData.map((e) => {
                          return (
                            <tr key={e.id}>
                              <td>{e.user_name}</td>
                              <td>{e.email}</td>
                              <td>{e.course}</td>
                              <td>{e.provider}</td>
                              <td>{e.price} EGP</td>
                              <td>
                                <div className="requestsActionsContainer">
                                  {e.status === "Approved" ? (
                                    <div className="approvedText">
                                      <FontAwesomeIcon icon={faCheck} />
                                      Approved
                                    </div>
                                  ) : e.status === "Rejected" ? (
                                    <div className="rejectedText">
                                      <FontAwesomeIcon icon={faXmark} />
                                      Rejected
                                    </div>
                                  ) : e.status === "Canceled" ? (
                                    <div className="rejectedText">
                                      <FontAwesomeIcon icon={faXmark} />
                                      Canceled
                                    </div>
                                  ) : e.status === "Booked" ? (
                                    <div className="approvedText">
                                      <FontAwesomeIcon icon={faCheck} />
                                      Booked
                                    </div>
                                  ) : e.status === "Pending Approval" ? (
                                    <div className="approvedText">
                                      Pending Approval
                                    </div>
                                  ) : e.status === "New" ? (
                                    <div
                                      style={{ width: "130px", margin: "auto" }}
                                    >
                                      <button
                                        className="approveButton"
                                        onClick={() => {
                                          handleStatus(e.id, 1);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faCheck} />
                                      </button>
                                      <button
                                        className="rejectButton"
                                        onClick={() => {
                                          handleStatus(e.id, 2);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faXmark} />
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <CustomPagination
                      pageNum={page}
                      handlePageChange={handlePageChange}
                      lastPage={allData.lastPage}
                    />
                  </>
                )}
              </div>
            </>
          ) : null}
          {openPopup ? (
            <AlertPopup
              title={status}
              subTitle={message}
              openPopup={handlePopup}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RequestsPage;
