import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import styles from "./PopUpProfile.module.css";
import xbutton from "../assets/images/xbutton.png";
import avatar from "../assets/images/user.png";
import Swal from "sweetalert2";
const AdminProfilePopup = ({ open, handleClose, id }) => {
  const { t } = useTranslation("global");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userData, setuserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userImage, setuserImage] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dob: "",
    gender: "",
    graduated: "",
    education: "",
    university: "",
    industry: "",
    area: "",
    phone: "",
  });
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`https://ed.edmart.co.uk/api/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data.data)
      .then((data) => {
        setuserData(data);
        setLoading(true);
        setuserImage(data.image);
        setFormData({
          name: data.name || "",
          email: data.email || "",
          dob: data.dob || "",
          gender: data.gender || "",
          graduated: data.graduated || "",
          education: data.education || "",
          university: data.university || "",
          industry: data.industry || "",
          area: data.area || "",
          phone: data.phone || "",
        });
      });
  }, [id]);
  useEffect(() => {
    // Update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: windowWidth >= 500 ? 400 : 310, // Use the state to set width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle image change

  return (
    <Modal open={open} onClose={handleClose} style={{ zIndex: 2 }}>
      <div className={styles.boxstyle}>
        <div className={styles.boxtitle}>
          <h1>Show User Profile</h1>
          <button onClick={handleClose}>
            <img src={xbutton} alt="" />
          </button>
        </div>

        <div className={styles.editImageProfile2}>
          <div className={styles.editImageProfileimage2}>
            {loading ? (
              <img src={userImage} alt="User Profile" />
            ) : (
              <img src={avatar} alt="Default Avatar" />
            )}
          </div>
        </div>

        <form>
          <TextField
            className={styles.inputx}
            label={t("firstName")}
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            disabled
          />
          <TextField
            className={styles.inputx}
            label={t("email")}
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            disabled
          />
          <div className={styles.inputsparent}>
            <TextField
              className={styles.inputx}
              label={t("phone")}
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled
            />
            <span></span>
            <TextField
              className={styles.inputx}
              label={t("area")}
              name="area"
              value={formData.area}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled
            />
          </div>
          <div className={styles.inputsparent}>
            <TextField
              label={t("dateOfBirth")}
              name="dob"
              type="date"
              value={formData.dob}
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              required
              disabled
            />
            <span></span>
            <TextField
              label={t("gender")}
              name="gender"
              select
              value={formData.gender}
              onChange={handleChange}
              margin="normal"
              required
              disabled
            >
              <MenuItem value="male">{t("male")}</MenuItem>
              <MenuItem value="female">{t("female")}</MenuItem>
            </TextField>
          </div>
          <div className={styles.inputsparent}>
            <TextField
              label={t("graduated")}
              name="graduated"
              select
              value={formData.graduated}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              disabled
            >
              <MenuItem value="1">{t("yes")}</MenuItem>
              <MenuItem value="0">{t("no")}</MenuItem>
            </TextField>
            <span></span>
            <TextField
              className={styles.inputx}
              label={t("education")}
              name="education"
              value={formData.education}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled
            />
          </div>
          <div className={styles.inputsparent}>
            <TextField
              className={styles.inputx}
              label={t("university")}
              name="university"
              value={formData.university}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled
            />
            <span></span>
            <TextField
              className={styles.inputx}
              label={t("industry")}
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              fullWidth
              margin="normal"
              disabled
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AdminProfilePopup;
